import React from "react";

function FooterContent() {
  return (
    <div>
      <h3>
        © 2025 FrontendMeta | Developed by Prathmesh Vhatkar. Personal Portfolio
        | All Rights Reserved
      </h3>
    </div>
  );
}

export default FooterContent;
